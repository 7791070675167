.get-started-container {
    display: flex;
    justify-content: start;
    width: 100%;
}

.get-started {
    width: 100%;
}

.get-started-form {
    margin-top: 2em;
    align-items: start;
    margin-bottom: 5em;
}

.get-started-sub-title {
    max-width: 700px;
    font-size: 28px;
    font-family: "Ubuntu";
    margin: 0.5em;
    margin-bottom: 0;
    font-weight: 600;
}

.get-started {
    width: auto;
    margin-left: 1em;
    margin-right: 1em;
}

.get-started-input-container {
    display: flex;
    width: 100%;
}

.get-started-input {
    max-width: 440px;
}

.get-started-button {
    max-width: 200px;
}

@media screen and (max-width: 750px) {
    .get-started-container {
        justify-content: center;
    }

    .get-started-form {
        max-width: 90vw;
        margin-left: unset;
        margin-right: unset;
    }

    .get-started-input,
    .get-started-button {
        width: 100%;
        max-width: unset;
        margin: 8px;
        margin-left: 0px !important;
    }

    .get-started-input-container {
        flex-direction: column;
    }

    .get-started-sub-title {
        font-size: 18px;
        margin: 0px;
    }

}