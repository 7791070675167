:root {
  font-family: "Ubuntu", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/public/fonts/Ubuntu-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/public/fonts/Ubuntu-Bold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  background-color: #ebf2ed;
  color: rgb(34, 34, 34);
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Ubuntu";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::after {
  content: "";
  background: url("./assets/images/logo-bg.svg");
  background-repeat: no-repeat;
  background-size: 80vw 80vh;
  background-position: 20vw 15vh;
  opacity: 0.10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -2;
}

/* FIXME: use these variables instead of hardoced colors */
.theme-light {
  --bg-color: "#EBF2ED";
  --primary-green: #80D43E;
  --primary-green-shade: "#80D43E88";
  --white: "#FFFFFF";
  --black: "rgb(34,34,34)";
  --error: "rgb(255, 30, 30)";
  --dark_grey: "#878787";
  --light_grey: "#bbbbbb";
  --light_grey_shade: "#bbbbbb88";
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.main-content {
  align-items: center;
  justify-content: center;
  display: flex;
  max-width: min(95vw, 1000px);
  margin-top: 1em;
}

.main-text-content {
  align-items: center;
  display: flex;
  max-width: min(95vw, 800px);
}

.root-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.spacer {
  flex-grow: 1;
}

.visible {
  opacity: 1 !important;
}

.invisible {
  opacity: 0 !important;
}

code {
  font-family: "Ubuntu";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.nav {
  text-decoration: none;
}

.link {
  margin-top: 15px;
  font-size: 0.8em;
  font-weight: 700;
  color: #80d43e;
}


label {
  align-self: flex-start;
  justify-content: center;
  color: rgb(34, 34, 34);
  margin-bottom: 0.5em;
}

.basic-button {
  margin-top: 1em;
  font-family: "Ubuntu";
  font-size: 1em;
  font-weight: 700;
  text-shadow: 1px 1px 2px #00000055;
  background-color: #80d43e;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-width: 0px;
  color: white;
  width: 100%;
  white-space: nowrap;
}

.circle-button {
  background-color: #80d43e;
  height: 4em;
  width: 4em;
  border-radius: 2em;
  justify-content: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: white;
  border-width: 0px;
  cursor: pointer;
}

.basic-button:hover {
  transform: scale(1.02);
}

.circle-button:hover {
  transform: scale(1.02);
}


.popup-container {
  background-color: white;
  z-index: 1000;
  width: 100%;
  max-width: 600px;
  align-items: start;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 1em !important;
  padding: 1.5em !important;
  box-sizing: border-box;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
}

.input-container {
  width: 350px;
  max-width: 100%;
  padding: 0em;
  align-items: start;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 0.5em;
  box-sizing: border-box;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
}

.container {
  padding-left: calc(10vw - 50px);
  padding-right: calc(10vw - 50px);
  padding-top: 2vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 0px;
}

@media screen and (min-width: 1100px) {
  .container {
    padding-left: 0;
    padding-right: 0;
    max-width: 975px;
  }
}

@media screen and (max-width: 700px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #80d43e;
  opacity: 1;
  width: 100%;
  border-radius: 50%;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

input[type="submit"] {
  font-size: 1em;
  font-family: "Ubuntu";
  font-weight: bold;
}

input[type="number"] {
  font-size: 1em;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 1em;
  font-size: 1em;
  font-family: "Ubuntu";
  font-weight: 900;
  width: 100%;
  background-color: transparent;
  border-width: 0px;
  border-bottom: 2px solid #80d43e;
  height: 50px;
  box-sizing: border-box;
  font-family: "Ubuntu";
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
  border-bottom: 2px solid white;
  /* Change border color on focus */
  outline: none;
  /* Remove default focus outline */
}

.selection {
  width: 100%;
}

.selection__value-container {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.selection__input {
  height: 38px !important;
}

.selection__menu {
  margin: 4px !important;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 0px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  accent-color: #80d43e;
}