.nav-parent {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    background-color: #ebf2ed;
    margin: 0;
    padding: 0;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    z-index: 99;
}

.nav-logo {
    height: 35px;
    margin: 1em;
}

.nav-links {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: end;
    margin-right: 1em;
}

.nav-list {
    display: flex;
    flex-direction: row;
    row-gap: 1em;
    column-gap: 2em;
    padding: 0px;
    margin: 1em;
    align-items: center;
}

.nav-toggle {
    cursor: pointer;
    display: none;
    z-index: 100;
}

.nav-menu {
    z-index: 100;
}

.nav-item {
    list-style-type: none;
}

.nav-item-selected {
    list-style-type: none;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: rgba(128, 128, 128, 0.1);
    border-radius: 4px;
}

@media screen and (max-width: 1150px) {
    .nav-list {
        flex-direction: column;
        margin-top: 6em;
        align-items: start;
    }

    .nav-toggle {
        display: block;
    }

    .nav-menu {
        position: fixed;
        background-color: #80D43E;
        top: 0;
        right: -100%;
        height: 100%;
        width: 70%;
        min-width: 200px;
        max-width: 400px;
        margin-top: 0;
        box-shadow: 0px 0px 50px 50px rgba(0, 0, 0, 0.35);
        transition: right 0.3s cubic-bezier(.62, 0, .2, .99);
    }

    .nav {
        color: #FFFFFF;
    }

    .nav-link {
        color: #FFFFFF;
        font-size: large;
        font-weight: bold;
    }

    .nav-item,
    .nav-item-selected {
        padding: 10px;
        /*FIXME: padding + width 100% shenanigans? */
        width: calc(100% - 20px);
    }

    .nav-item-selected {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
}

@media screen and (min-width: 1150px) {
    .nav-link {
        color: #80D43E;
        font-size: large;
        font-weight: bold;
    }
}

.nav-link {
    display: block;
    font-size: large;
    font-weight: bold;
    width: 100%;
}

.show-menu {
    top: 0;
    right: 0;
}

.hide {
    display: none;
}