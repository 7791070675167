.today-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 2em;
}

.today-stats-title {
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    text-shadow: 1px 1px 3px black;
}

.today-stat {
    color: #ffffff;
    font-size: 36px;
    font-weight: 900;
    text-shadow: 1px 1px 3px black;
}

.today-stats-container {
    margin-top: 2em;
    border: 2px solid #80D43E;
    border-radius: 4px;
    transition: opacity 0.5s ease;
    opacity: 0;
    background-color: #000000bb;
    width: 100%;
    flex: 0 0 48%;
    box-sizing: border-box;
    padding-top: 0.3em;
    padding-bottom: 0.7em;
    padding-left: 1em;
    padding-right: 1em;
}

@media screen and (max-width: 450px) {
    .today-stats-title {
        font-size: 12px;
    }

    .today-stat {
        font-size: 24px;
    }
}