.slide-content-container {
    display: flex;
    flex-direction: column;
    width: 750px;
    box-sizing: border-box;
    padding-top: max(min(5vw, 80px), 2em);
    padding-right: max(min(5vw, 100px), 0.5em);
    padding-left: max(min(5vw, 100px), 0.5em);
    align-items: space-between;
    justify-content: space-between;
    height: 100%;
    padding-bottom: max(min(10vw, 150px), 5em);
}

.slide-title {
    font-size: 40px;
    color: whitesmoke;
    font-weight: 900;
    text-shadow: 1px 1px 3px black;
    margin-top: 0px;
}

.slide-description {
    font-size: 28px;
    color: whitesmoke;
    font-weight: 500;
    text-shadow: 1px 1px 3px black;
}

@media screen and (max-width: 750px) {
    .slide-content-container {
        width: 100%;
    }

    .slide-title {
        font-size: 34px;
    }

    .slide-description {
        font-size: 20px;
    }
}

.embla {
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

.embla__viewport {
    overflow: hidden;
}

.embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
}

.embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 100%;
    height: 65vh;
    max-height: 850px;
}


.embla__controls {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    top: -30px;
    width: 100%;
    overflow: hidden;
}

.embla__dots {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.embla__dot {
    display: flex;
    border-radius: 50%;
    box-sizing: border-box;
    height: 14px;
    width: 14px;
    border: 3px solid #80D43E;
    cursor: pointer;
    margin: 6px;
    box-shadow: 1px 1px 3px black;
}

.embla__dot--selected {
    background-color: #80D43E;
}

.embla__around__dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 0 0 50%;
    justify-content: start;
}

.embla__autoplay__toggle {
    margin-left: 10px;
    height: 14px;
    width: 14px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.embla__progress {
    margin-left: 25px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 2px solid #80D43E;
    position: relative;
    height: 10px;
    justify-self: flex-end;
    align-self: center;
    overflow: hidden;
    width: 50px;
    justify-self: center;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 1px 1px 3px black;
}

.embla__progress__bar {
    background-color: #80D43E;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
}

.embla__progress--hidden {
    opacity: 0;
}

.embla__progress__bar {
    animation-name: autoplay-progress;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

.embla__progress--hidden .embla__progress__bar {
    animation-play-state: paused;
}

.embla__autoplay__toggle__icon {
    filter: drop-shadow(1px 1px 3px black);
}

@keyframes autoplay-progress {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(100%, 0, 0);
    }
}



@media screen and (min-width: 1200px) {

    .embla__viewport {
        border-radius: 15px;
        box-shadow: 2px 2px 25px black;
        background-color: black;
        margin-top: 1em;
    }

    .embla__slide--not-selected {
        opacity: 0.8;
    }

    .embla__slide {
        transition: opacity 300ms ease-in;
    }

    .embla__progress {
        width: 100px;
    }
}