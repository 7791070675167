.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

.key-point {
    margin-top: 5em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
}

.key-img {
    height: 20vw;
    width: 20vw;
    max-width: 200px;
    max-height: 200px;
    margin-right: 5vw;
    border-radius: 150px;
    box-shadow: 1px 1px 15px 5px #0000002a;
    margin-top: 20px;
    fill: primary-green;
}

.key-title {
    font-family: "Ubuntu";
    font-weight: 900;
    font-size: 40px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.key-description,
.key-read-more-bullet {
    font-size: 30px;
}

.key-read-more-bullet {
    font-size: 30px;
    margin-bottom: 0.5em;
}

.landing-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: max(min(5vw, 100px), 0.5em);
    margin-left: max(min(5vw, 100px), 0.5em);
}


@media screen and (max-width: 750px) {
    .key-title {
        font-size: 20px;
    }

    .key-description,
    .key-read-more-bullet {
        font-size: 16px;
    }
}