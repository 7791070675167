.device-list {
    width: 920px;
}

.device-list-item {
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-shadow: 0px 0px 29px 2px rgba(0, 0, 0, 0.02);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-bottom: 40px;
    width: 920px;
    height: 210px;
}

@media screen and (max-width: 1000px) {

    .device-list,
    .device-list-item {
        width: 650px;
    }
}

@media screen and (max-width: 700px) {

    .device-list,
    .device-list-item {
        width: 330px;
    }

    .device-list-item {
        height: 210px;
    }
}

.device-list-item:hover {
    transform: scale(1.01);
}

.device-info {
    box-sizing: content-box;
    min-width: 250px;
    flex: 1 1;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0.5em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    max-width: 50em;
    background-color: #ffffff;
    box-shadow: 0px 0px 29px 2px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
}

.device-graph-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0.5em;
}

.device-graph {
    display: flex;
    justify-content: center;
    width: 300px;
}

.device-status-info {
    margin-right: 1em;
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    align-items: end;
    white-space: nowrap;
    padding: 0px;
    justify-content: start;
    min-width: 305px;
}

.device-list-image {
    max-width: 280px;
}

@media screen and (max-width: 1000px) {
    .device-list-image {
        display: none;
    }
}

@media screen and (max-width: 700px) {

    .device-graph {
        height: 120px;
    }

    .device-graph-container {
        flex-direction: column-reverse;
    }

    .device-status-info {
        margin: 0;
        gap: 0em;
        align-items: start;
    }
}


.device-setup-container,
.device-setup-nav {
    z-index: 900;
}

.device-setup-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}


.device-on {
    transform: rotate(90deg);
    stroke: #70ff02;
    stroke-width: 3px;
    height: 30px;
}

.device-off {
    transform: rotate(-90deg);
    stroke: rgb(255, 28, 77);
    stroke-width: 3px;
    height: 30px;
}

.device-log-entry {
    margin-left: 10px;
}


.device-header-image {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 30px;
    margin-left: 25px;
}

@media screen and (max-width: 430px) {
    .device-header-image {
        display: none;
    }
}

.settings-unit {
    color: #80D43E;
}

.settings-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}


.settings-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.settings-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.settings-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 10em;
    min-width: 10em;
    max-width: 10em;
}

.settings-cell-number-input,
.settings-time-input {
    margin: 0px;
    margin-right: 0.5em;
    width: 5em;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border-width: 0px;
    height: 2em;
    box-sizing: border-box;
    font-family: "Ubuntu";
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-align: end;
}

.settings-time-input {
    width: 4em;
    padding-right: 30px;
    margin-right: -30px;
}

.settings-time-divider {
    margin-right: 0.5em;
    margin-left: 1em;
}

.settings-time-unit {
    pointer-events: none;
}

.settings-check-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.disabled {
    background-color: #bbbbbb;
    cursor: default;
}

.disabled:hover {
    transform: none;
}